<template>
    <div class="train-list main-wrapper">
        <div class="admin-top">
            <div class="top-left">
                <span class="left-title">实训列表</span>
            </div>
        </div>
        <div class="table-bin">
            <el-button type="text" size="small" class="del-btn" @click="delMultipleTrainBtn">
                删除（已选择{{selectTrainList.length}}个）
            </el-button>
        </div>
        <el-table :data="trainLists" style="width: 100%; margin: 10px 0; min-height: calc(100vh - 507px);" size="medium" class="my-video-table"
                  :header-cell-style="{fontWeight: 'normal', height: '40px', color: '#333333', background: '#F6F5F8'}"
                  :cell-style="{fontSize: '14px',color: '#333333'}" @selection-change="handleTrain">
            <el-table-column
                    type="selection"
                    width="50">
            </el-table-column>
            <!--<el-table-column prop="id" align="center" label="序号" :resizable="false" width="50"></el-table-column>-->
            <el-table-column align="left" label="标题" :resizable="false" min-width="200">
                <template slot-scope="scope">
                    <span>{{scope.row.title}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="time" align="center" label="创建时间" :resizable="false" width="120"></el-table-column>
            <el-table-column label="操作" align="center" width="280">
                <template slot-scope="scope" :resizable="false">
                    <el-button :underline="false" type="text" class="video-btn" @click="topViewTrainBtn(scope.row)">查看</el-button>
                    <el-button :underline="false" type="text" class="video-btn" @click="editTrainBtn(scope.row)">编辑</el-button>
                    <el-button :underline="false" type="text" class="del-btn" @click="delTrainBtn(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                class="pages-center" style="padding-bottom: 10px;"
                :current-page="trainPages.currentPageNum"
                :page-size="trainPages.eachPageNum"
                layout="prev, pager, next, jumper"
                :total="trainPages.total"
                @current-change="trainCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    export default {
        name: "TrainList",
        data() {
            return {
                //实训列表
                trainLists: [],
                //已选的列表集合
                selectTrainList: [],
                //分页
                trainPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
            }
        },
        mounted() {
          this.getTrainLists();
        },
        methods: {
            //获取实训列表
            getTrainLists() {
                let param = {
                    paging: 1,
                    page: this.trainPages.currentPageNum,
                    pageSize: this.trainPages.eachPageNum
                }
                this.$http.axiosGetBy(this.$api.train, param, (res) => {
                    if (res.code === 200) {
                        this.trainLists = res.data.data;
                        this.trainPages.total = res.data.total;
                    } else {
                        this.$message.error(res.message);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //多选删除
            delMultipleTrainBtn() {
                this.$confirm('是否删除实训，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'btn-blue',
                    type: 'warning',
                }).then(() => {
                    let arrTmp = this.selectTrainList.map(selectItem => selectItem.id);
                    let formData = new FormData();
                    formData.append('id',JSON.stringify(arrTmp));
                    this.$http.axiosPost(this.$api.train_delete, formData, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.message);
                            this.getTrainLists();
                        } else {
                            this.$message.error(res.message);
                        }
                    }, (err) => {
                        console.log(err);
                    })
                }).catch(() => {
                    this.$message.info('已取消删除');
                });
            },
            //删除
            delTrainBtn(row) {
                this.$confirm('是否删除该实训，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'btn-blue',
                    type: 'warning',
                }).then(() => {
                    let idArr = [];
                    let formData = new FormData();
                    idArr.push(row.id);
                    formData.append('id',JSON.stringify(idArr));
                    this.$http.axiosPost(this.$api.train_delete, formData, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.message);
                            this.getTrainLists();
                        } else {
                            this.$message.error(res.message);
                        }
                    }, (err) => {
                        console.log(err);
                    })
                }).catch(() => {
                    this.$message.info('已取消删除');
                });
            },
            //选择的实训集合
            handleTrain(val) {
                this.selectTrainList = val;
            },
            //分页
            trainCurrentChange(val) {
                this.trainPages.currentPageNum = val;
                this.getTrainLists();
            },
            //查看实训详情
            topViewTrainBtn(row) {
                this.$router.push({
                    path: '/admin/train/detail',
                    query: {
                        id: row.id
                    }
                })
            },
            //编辑实训
            editTrainBtn(row) {
                this.$router.push({
                    path: '/admin/train/createtrain',
                    query: {
                        id: row.id
                    }
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .video-btn {
        color: #1D33E1;
        margin-right: 10px;
        &:hover {
            color: #4557e8;
        }
    }
    .del-btn {
        color: #FB0338;
        margin-left: 10px;
        &:hover {
            color: #CD062E;
        }
    }
    .pages-center {
        ::v-deep .el-pager li {
            background-color: transparent !important;
            width: 24px;
            min-width: 24px;
            height: 24px;
            line-height: 26px;
        }
        ::v-deep .el-pager li:not(.disabled).active {
            background-color: #333FD9 !important;
            border-radius: 50%;
            color: #fff;
        }
    }
</style>